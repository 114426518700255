<template>
    <div>
        <div class="SecondTitle">
            <span>
                {{ $t("enterOTP.secondTitle").format(otpcode) }}
            </span>
        </div>
        <div>
            <span class="tips">
                {{ $t("enterOTP.tips") }}
            </span>
        </div>

        <div style="margin-top: 20px;">
            <input id="otpShadow" ref="cardNo" type="tel" v-model="otpValue" center />

            <van-password-input :value="otpValue" :gutter="10" :mask="false" :length="otpValueLength"
                @click="showSystemKeyboard()" :focused="showKeyboard" @focus="showKeyboard = true" @change="handleInput" />
            <!-- <van-password-input  :value="otpValue" :gutter="10" :mask="false" :length=otpValueLength
                :focused="showKeyboard" @focus="showKeyboard = true" /> -->
            <!-- 数字键盘 -->
            <!-- <van-number-keyboard v-model="otpValue" :show="showKeyboard" @blur="showKeyboard = false" /> -->
        </div>

        <div>
            <p class="resend-otp" v-if="number != 0">
                {{ $t("enterOTP.resendOTP") }} {{ this.number }}s
            </p>
            <!-- <p class="resend-otp" v-else @click="start(1)">
                {{ $t("enterOTP.resendOTP") }}
            </p> -->
            <van-button v-else @click="start(1)" size="large" round class="button-verify">
                {{ $t("enterOTP.resendOTP") }}
            </van-button>
        </div>

        <van-button @click="VerifyOtp" :disabled="((otpValue.length != otpValueLength) || pageLoading)" size="large" round
            class="button-verify">
            <!-- <van-loading class="loading-bg" type="spinner" color="#ff5d5d"  v-show="pageLoading"/> -->
            {{ $t("enterOTP.verify") }}
        </van-button>

        <van-dialog v-model="showDialog" :show-confirm-button="false">
            <template v-slot:title>
                <p style="text-align: left">
                    {{ $t("enterOTP.invalidOTP") }}
                </p>
            </template>
            <template v-slot:default>
                <div>
                    <p>
                        {{ $t("enterOTP.OTPRuleMessage") }}
                    </p>
                    <van-button style="width: 95%;margin-bottom: 10px" class="button-verify" @click="closeDialog()">
                        {{ $t("enterOTP.ok") }}
                    </van-button>
                </div>
            </template>
        </van-dialog>

        <van-dialog v-model="showDialog2" :show-confirm-button="false">
            <template v-slot:title>
                <p style="text-align: left">
                    Pass
                </p>
            </template>
            <template v-slot:default>
                <div>
                    <p>
                        Successfully
                    </p>
                    <van-button style="width: 95%;margin-bottom: 10px" class="button-verify" @click="closeDialog()">
                        {{ $t("enterOTP.ok") }}
                    </van-button>
                </div>
            </template>
        </van-dialog>
        <!-- <van-loading class="loading-bg" type="spinner" color="#ff5d5d"  v-show="pageLoading"/> -->
    </div>
</template>

<script>
import {
    Dialog,
    PasswordInput,
    NumberKeyboard,
    Switch,
    Cell,
    DropdownMenu,
    DropdownItem,
    Divider,
    Button,
    Field,
    Toast
} from 'vant';
import { generateOtp, VerifyOtp } from '@/api/eformApi';
export default {
    name: "EnterNumber",
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [NumberKeyboard.name]: NumberKeyboard,
        [PasswordInput.name]: PasswordInput,
        [Switch.name]: Switch,
        [Cell.name]: Cell,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Divider.name]: Divider,
        [Button.name]: Button,
        [Field.name]: Field
    },
    props: {
        brandConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    watch: {
        otpValue() {
            // this.otpValue = this.otpShadow

            // 非数字替换成数字
            this.otpValue = this.otpValue.replace(/[^0-9]/g, '')

            if (this.otpValue.length > this.otpValueLength) {
                this.otpValue = this.otpValue.substring(0, this.otpValueLength)
            }
        }
    },
    created() {
        this.phoneNumber = this.$route.query.phoneNumber
        this.tpid = this.$route.params.tpid
        this.otpcode = this.$route.params.otpcode == undefined ? '' : this.$route.params.otpcode
        console.log('storeId', this.$route.params.storeId)
        this.otpValueLength = 6
        // this.sendOtp()
        this.start(0)
    },
    data() {
        return {
            showKeyboard: false,
            otpValue: '',
            number: 60,
            time: '',
            isValidOtp: true,
            showDialog: false,
            showDialog2: false,
            secondTitle: false,
            phoneNumber: '',
            otpValueLength: 0,
            pageLoading: false,
            linkToken: '',
            otpShadow: '',
            country: '',
            otpcode: '',
            tpid: '',
        };
    },
    methods: {
        closeDialog() {
            this.showDialog = false
            this.showDialog2 = false
        },
        showSystemKeyboard() {
            this.showKeyboard = true
            this.$refs.cardNo.focus()


        },
        VerifyOtp() {
            // Toast.loading({
            //     duration: 0, // 持续展示 toast
            //     forbidClick: true, // 禁用背景点击
            //     loadingType: 'spinner',
            //     message: 'Verifying...'
            // });
            //TODO: gray button
            this.pageLoading = true;
            console.log(this.$route.params.countryPath == 'TH' ? sessionStorage.getItem('th_request_id') : '')
            VerifyOtp({
                phoneno: this.phoneNumber,
                pincode: this.otpValue,
                country: this.$route.params.countryPath,
                brandName: this.brandConfig.name,
                brandAbbr: this.brandConfig.abbr,
                request_id: this.$route.params.countryPath == 'TH' ? sessionStorage.getItem('th_request_id') : '',
            }).then((res) => {
                if (res.success) {
                    this.$router.push({
                        // path: '/' + this.$route.params.countryPath + '/' + this.$route.params.brand + '/Registration/' + this.$route.params.lang + '?storeId=' + this.$route.params.storeId,
                        name: 'Registration',
                        params: {
                            lang: this.$route.params.lang,
                            brand: this.$route.params.brand,
                            countryPath: this.$route.params.countryPath,
                            tpid: this.tpid,
                        },
                        query: {
                            storeId: this.$route.query.storeId,

                        }
                    })
                } else {
                    this.showDialog = true
                    this.otpValue = ''
                    this.pageLoading = false;
                }
            }).catch(() => {
                Toast.fail(this.$t('common.error'))
            });
        },
        start(type) {
            if (type == 0) {
                this.countdown(60)
            } else {
                if (this.number == 0) {
                    generateOtp({
                        phoneno: this.phoneNumber,
                        country: this.$route.params.countryPath,
                        brandName: this.brandConfig.name,
                        brandAbbr: this.brandConfig.abbr,
                    }).then((res) => {
                        if (res.success && res.data.request_id) {
                            sessionStorage.setItem('th_request_id', res.data.request_id)
                            Toast.success(this.$t('enterNumber.toastSuccess'))
                            this.countdown(60)
                        } else {
                            console.log(res.data)
                            Toast.fail(this.$t('enterNumber.toastFail'))
                        }
                    }).catch(() => {
                        Toast.fail(this.$t('common.error'))
                    });
                }
            }
        },
        countdown(num) {
            this.number = num
            let that = this
            this.time = setInterval(() => {
                that.number--
                if (that.number == 0) {
                    clearInterval(that.time)
                }
            }, 1000)
        },
        handleInput() {
            console.log(8888)
        }
    }
}
</script>

<style scoped>
>>>.van-popup--top {
    width: 25%;
    padding-left: 16px
}

.button-verify {
    display: inline;
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 20px;
}

.resend-otp {
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    font-family: Helvetica-Regular;
}

.van-password-input__security li {
    background-color: #ececec;
    border-radius: 6px;
}

>>>.van-dialog__header {
    padding-top: 0px;
    margin-left: 5%;
}

>>>.van-dialog__content {
    margin-left: 5%;
}

>>>.van-dialog {
    border-radius: 6px;
}

#otpShadow {
    border-style: none;
    width: 100;
    height: 0;
    padding: 0;
}
</style>
